<template>
  <div class="home" id="quotaReviewList">
    <div style="text-align: left" >
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
          <el-form-item label="公司名称:">
          <el-select v-model="queryForm.tenantID" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in this.tenants"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间周期:">
        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                v-model="queryForm.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00','23:59:59']">
          </el-date-picker>
          </el-form-item>
          <el-form-item class="search">
            <el-button type="primary" @click="loadTable" icon="el-icon-search">查询</el-button>
            <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
          </el-form-item>
        </el-form>
    </div>
    <div style="text-align: left;margin-bottom: 20px;">
      <el-button type="primary" icon="el-icon-download" @click="exportTablesss">导出</el-button>
    </div>
    
    <div>
      <el-table
        :data="tableData"
        :header-cell-style="tableHeader"
        border
      >
        <el-table-column prop="sss" label="序号" width="50" align="center">
          <template slot-scope="scope">
            <span>
              {{
              scope.$index + 1 + (queryForm.pageNo - 1) * queryForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        
        <el-table-column prop="summaryNo" :show-overflow-tooltip="true"  label="账单编号" width="100px"/>
        <el-table-column :show-overflow-tooltip="true"  label="合作主体">四川思迈信息技术有限公司</el-table-column>
        <el-table-column prop="tenantId" :show-overflow-tooltip="true"  label="公司名称"/>
        <el-table-column prop="recordNumber" :show-overflow-tooltip="true"  label="工资人数" />
        <el-table-column prop="totalPayrollPayable" :show-overflow-tooltip="true" label="应发工资额" ></el-table-column>
        <el-table-column prop="totalFinalTax" :show-overflow-tooltip="true" label="最终个税" ></el-table-column>
        <el-table-column prop="totalNetSalary" :show-overflow-tooltip="true" label="实际发放工资额" ></el-table-column>
        <el-table-column prop="totalIncrementTaxation" :show-overflow-tooltip="true" label="服务费" >
          <template slot-scope="scope">{{ scope.row.totalIncrementTaxation+ scope.row.totalLocaltaxSurchargeTaxation }}</template>
        </el-table-column>
        <el-table-column prop="totalServicecharge" :show-overflow-tooltip="true" label="项目服务费" ></el-table-column>
        <el-table-column prop="totalInvoiceAmount" :show-overflow-tooltip="true" label="费用合计" >
          <template slot-scope="scope">
            {{ scope.row.totalIncrementTaxation+scope.row.totalLocaltaxSurchargeTaxation+scope.row.totalServicecharge }}
          </template>
        </el-table-column>

        <el-table-column prop="totalActualDeductibleExpenses" :show-overflow-tooltip="true" label="本月实际抵扣销售折让" ></el-table-column>
        <el-table-column prop="totalInvoiceAmount" :show-overflow-tooltip="true" label="开票金额" ></el-table-column>
        <el-table-column  :show-overflow-tooltip="true" label="钱袋宝收款" >0</el-table-column>
        <el-table-column prop="totalExpenses" :show-overflow-tooltip="true" label="银行收款" ></el-table-column>
        <el-table-column prop="totalDeductibleExpenses" :show-overflow-tooltip="true" label="次月可抵扣销售折让" ></el-table-column>
        <el-table-column prop="createTime" width="200px" :show-overflow-tooltip="true" label="上传时间" />
       
      </el-table>
      <!-- 分页栏 -->
      <el-pagination
        id="page"
        background
        align="right"
        @size-change="handleSizeChange"
        @current-change="salarysizeChange"
        :page-sizes="[5,10, 15, 20]"
        :page-size="queryForm.pageSize"
        :current-page="queryForm.pageNo"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryForm:{
        name:'',
        idno:'',
        mobile:'',
        tenantID:'',
        time:[],
        pageNo:1,
        pageSize:10
      },
      tenants:'',
      tableData:[],
      total:0,
      itemList:[],
      itemLists:[],
      diction: {
          code: "summaryStatus",
      },
      
      PayrollInfo:'',
      loading: false,
      salaryInfo:{
        buscod:'',
        yurref:'',
        bgndat:'',
        enddat:'',
        salarySummarizingId:'',
        salarySummarizingRecordsId:'',
        zhtenantID:'',
        createTime:'',
      },
      
    };
  },
  async created() {
    this.loadTable();
    
  },
  // 挂载查询方法
  mounted() {
    // this.findDictionariesItemList(this.diction);
    // this.findDictionariesItemLists(this.dictions);
  },
  methods: {
    
    // 查询
    async loadTable() {
      this.tableLoading = true;
      await this.api.salaryApi.financialSummary(this.queryForm).then(res => {
          res = res.data;
          if(res.code == 200){
            this.tableData = res.data.records;
            this.total = res.data.total;
          }else{
            this.tableData =  null;
            this.total = 0;
            this.$message.error(res.msg);
          }
        });
        this.listTenantInfos();
        // this.datareport()
    },

    //查询客户信息
    async listTenantInfos() {
      this.tableLoading = true;
      await this.api.salaryApi.finTenants(this.queryForm).then(res => {
        console.log("-----",res)
          this.tenants = res.data;
        });
    },

    async exportTableSuccess(){
        console.log("导出下载");
        await this.api.salaryApi.downloadstaffRecordInfo(this.queryForm).then(res =>{
          console.log(res)
          let fileNameEncode = "员工收入记录.xlsx";
          const link = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel"});
          console.log(blob);
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download",  decodeURIComponent(fileNameEncode));
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      },
    
    //获取支付结果
    async noResult(row) {
      row.loading = true;
      var time1 = row.createTime;
      var time2 = row.createTime;
      time1 = time1.split(' ')[0]
      var reg1 = new RegExp("-","g"); // 加'g'，删除字符串里所有的"a"
      time1 = time1.replace(reg1,"");
      // console.log(time1);
      this.salaryInfo.buscod = row.buscod;
      this.salaryInfo.yurref = row.yurref;
      this.salaryInfo.bgndat = time1
      this.salaryInfo.enddat = time1
      this.salaryInfo.createTime = time2
      this.salaryInfo.salarySummarizingId = row.salarySummarizingId;
      this.salaryInfo.salarySummarizingRecordsId = row.id
      this.salaryInfo.zhtenantID = row.tenantId
      console.log("-----row",this.salaryInfo)
      await this.api.salaryApi.finSalarySummaryInfo(this.salaryInfo).then(res => {
        console.log("-----",res)
        console.log("-----",res.data.code)
        if(res.data.code==200){
          this.loadTable();
          row.loading = false;
          this.$message.success("获取成功")
          
        }
      });
    },
    async exportTablesss(){
        console.log("导出下载");
        await this.api.salaryApi.downloadFinancialSummary(this.queryForm).then(res =>{
          console.log(res)
          let fileNameEncode = "账单汇总表.xlsx";
          const link = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel"});
          console.log(blob);
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download",  decodeURIComponent(fileNameEncode));
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      },
    // async findDictionariesItemList(param) {
    //   let response = await this.api.platformApi.findDictionariesItemList(param);
    //   console.log("数据字典", response);
    //   this.itemList = response.data.data;
    // },
    // 分页改变时触发
    salarysizeChange(page) {
      this.queryForm.pageNo = page;
      this.loadTable();
    },
    handleSizeChange(size) {
      this.queryForm.pageSize = size;
      this.loadTable();
    },
    // 重置
    onReset() {
      this.queryForm.time=[],
      this.queryForm.tenantID='';
      this.loadTable();
    },
    // 查看函数
    noDetails(row){
        this.$router.push({path: '/payrollLogDetails',query: {salarySummarizingRecordsId: row.id,createTime:row.createTime}})
    },
    // 导出
    derive() {},
    // async findDictionariesItemLists(param) {
    //   let response = await this.api.platformApi.findDictionariesItemList(param);
    //   console.log("数据字典", response);
    //   this.itemLists = response.data.data;
    // },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    afi(fmt){
      // Date.prototype.format = function(fmt) { 
          var o = { 
              "M+" : this.getMonth()+1,                 //月份 
              "d+" : this.getDate(),                    //日 
              "h+" : this.getHours(),                   //小时 
              "m+" : this.getMinutes(),                 //分 
              "s+" : this.getSeconds(),                 //秒 
              "q+" : Math.floor((this.getMonth()+3)/3), //季度 
              "S"  : this.getMilliseconds()             //毫秒 
          }; 
          if(/(y+)/.test(fmt)) {
                  fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
          }
          for(var k in o) {
              if(new RegExp("("+ k +")").test(fmt)){
                  fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
              }
          }
          return fmt; 
      // }       
    },
    //除法函数
    accDiv(arg1, arg2){
         if(arg1!=null){
          var t1 = 0, t2 = 0, r1, r2;
          try {
            t1 = arg1.toString().split(".")[1].length;
          }
          catch (e) {
          }
          try {
            t2 = arg2.toString().split(".")[1].length;
          }
          catch (e) {
          }
          r1 = Number(arg1.toString().replace(".", ""));
          r2 = Number(arg2.toString().replace(".", ""));
          return (r1 / r2) * Math.pow(10, t2 - t1);
         }
         else{
          return null
         }
        }
  }
};
</script>

<style scoped>
#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}
.sys-info{
  display: flex;
  font-size: 20px;
  margin-bottom: 10px;
  background: #00FFFF;
  border-radius: 10px;
}
.info-a{
  margin: 0 100px 0 10px;
}
.info-b{

}
.info-jc{
  color: red;
  font-weight: 900;
  font-size: 24px;
}
.search{
  margin-left: 50px;
}
</style>
