import '@/plugins/axios';
import Axios from 'axios';

/**
 * 基础设置-子账户管理
 */
 export const subAccountList = params => axios.post("/subAccount/subAccountList", params).then(data => data)

  /**
- 基础设置-子账户管理
*/
export const fundAccountList = params => axios.post("/subAccount/fundAccountList", params).then(data => data)



 