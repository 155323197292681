import Vue from 'vue'
import VueRouter from 'vue-router'
import SysUserList from '../views/system/user/SysUserList'
import sysdistribution from '../views/system/user/sysdistribution'
import qrCord from '../views/system/tenant/qrCord'
import DataDictionaries from '../views/system/dictionaries/DataDictionaries'
import addDic from '../views/system/dictionaries/addDic'
import addDictionaries from '../views/system/dictionaries/addDictionaries'
import Config from '../views/system/dictionaries/Config'
import sysuserDetails from '../views/system/user/sysuserDetails'
import SysRoleList from '../views/system/role/SysRoleList'
import addSysRoleList from '../views/system/role/addSysRoleList'
import editMenu from '../views/system/menu/editMenu'
import SysMenu from '../views/system/menu/SysMenu'
import menutree from '../views/menutree'
import login from '../views/system/home/login'
import groupManage from '../views/system/department/groupManage'
import SysAuditLogList from '../views/system/home/SysAuditLogList'
import SysPermission from '../views/system/permission/SysPermission'
import addUser from '../views/system/role/addUser'
import organization from '../views/system/organization/organization'
import channel from '../views/system/channel/channel'
import about from '../views/system/home/about'
import supplierManagementList from '../views/preloanmanagement/suppliermanagement/supplierManagementList'
import supplierManagementDetails from '../views/preloanmanagement/suppliermanagement/supplierManagementDetails'
import SysFundFlow from '../views/system/fund/SysFundFlow'
import SysFundAccount from '../views/system/fund/SysFundAccount'
import Withdrawal from '../views/system/fund/Withdrawal'
import WithdrawalSetting from '../views/system/fund/WithdrawalSetting'
import Detailed from '../views/system/fund/Detailed'
import orderpayment from '../views/system/fund/orderpayment'
import loanTrackingList from '../views/postloanmanagement/loantracking/loanTrackingList'
import loanTrackingDetails from '../views/postloanmanagement/loantracking/loanTrackingDetails'
import platformRepaymentList from '../views/postloanmanagement/loantracking/platformrepayment/platformRepaymentList'
import platformRepaymentDetails from '../views/postloanmanagement/loantracking/platformrepayment/platformRepaymentDetails'
import loanList from '../views/postloanmanagement/loanlist/loanList'
import loanListDetails from '../views/postloanmanagement/loanlist/loanListDetails'
import preliminaryReviewOfLoansList from '../views/postloanmanagement/preliminaryreviewofloans/preliminaryReviewOfLoansList'
import preliminaryReviewOfLoansDetails from '../views/postloanmanagement/preliminaryreviewofloans/preliminaryReviewOfLoansDetails'
import loanReviewList from '../views/postloanmanagement/loanreview/loanReviewList'
import loanReviewDetails from '../views/postloanmanagement/loanreview/loanReviewDetails'
import loanProcessingList from '../views/postloanmanagement/loanprocessing/loanProcessingList'
import loanProcessingDetails from '../views/postloanmanagement/loanprocessing/loanProcessingDetails'
import productManagementList from '../views/preloanmanagement/productmanagement/productManagementList'
import productManagementDetails from '../views/preloanmanagement/productmanagement/productManagementDetails'
import addProduct from '../views/preloanmanagement/productmanagement/addProduct'
import Setting from '../views/preloanmanagement/suppliermanagement/Setting';
import invoiceFindList from '../views/invoiceAdministration/invoiceFindList'
import invoiceDetails from '../views/invoiceAdministration/invoiceDetails'
import payment from '../views/invoiceAdministration/payment'
import updateInvoice from '../views/invoiceAdministration/updateInvoice'
import bulkPayment from '../views/invoiceAdministration/bulkPayment'
import whiteList from '../views/preloanmanagement/whitelist/whiteList'
import whiteListDetails from '../views/preloanmanagement/whitelist/whiteListDetails'
import platformPayList from '../views/platformPay/platformPayList'
import summaryDetails from '../views/salary/summaryDetails'
import summaryApprovalDetails from '../views/salary/summaryApprovalDetails'
import salaryApproval from '../views/salary/salaryApproval'
import salaryList from '../views/salary/salaryList'
import spiritualLabor from '../views/salary/spiritualLabor'
import spiritualLaborApproval from '../views/salary/spiritualLaborApproval'
import contractList from '../views/contract/contractList'
import templateList from '../views/contract/templateList'
import signContract from '../views/contract/signContract'
import batchSignTaskList from '../views/contract/multipleTask'
import subAccountList from '../views/account/subAccountList'
import PayrollDeclarationList from '../views/salary/PayrollDeclarationList'
import ptdetail from '../views/system/home/ptDetail'
import zcdetails from '../views/system/home/zcDetails'
import pages from '../views/system/home/pages'
import tran from '../views/salary/tran'
import transfer from '../views/account/transfer'
import fundAccountList from '../views/account/fundAccountList'
import financialSummary from '../views/salary/financialSummary'
import personnelDetails from '../views/salary/personnelDetails'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/menutree',
    name: 'menutree',
    component: menutree,
    redirect: '/SysUserList',
    children: [
      { path: '/SysUserList', component: SysUserList },
      { path: '/SysRoleList', component: SysRoleList },
      { path: '/tenantList',name: 'tenantList',component: () => import(/* 租户列表 */ '../views/system/tenant/index.vue')},
      { path: '/addOrUpdateTenant/:type',name: 'addOrUpdateTenant',component: () => import(/* 添加修改租户 */ '../views/system/tenant/addOrUpdate.vue')},
      { path: '/menberList', name: 'menberList', component: () => import('../views/system/menber/index') },
      { path: '/SysMenu', component: SysMenu },
      { path: '/GroupManage', component: groupManage },
      { path: '/SysAuditLogList', component: SysAuditLogList },
      { path: '/SysPermission', component: SysPermission },
      { path: '/DataDictionaries', component: DataDictionaries },
      { path: '/AddDic', component: addDic },
      { path: '/AddDictionaries', component: addDictionaries },
      { path: '/Config', component: Config },
      { path: '/Organization', component: organization },
      { path: '/Channel', component: channel },
      { path: '/supplierManagementList', component: supplierManagementList },
      { path: '/SysFundFlow', component: SysFundFlow },
      { path: '/SysFundAccount', component: SysFundAccount },
      { path: '/loanTrackingList', name: "loanTrackingList", component: loanTrackingList },
      { path: '/loanTrackingDetails', name: "loanTrackingDetails", component: loanTrackingDetails },
      { path: '/platformRepaymentList', component: platformRepaymentList },
      { path: '/supplierManagementDetails', name: 'supplierManagementDetails', component: supplierManagementDetails },
      { path: '/Setting', name: 'Setting', component: Setting },
      { path: '/platformRepaymentDetails', name: "platformRepaymentDetails", component: platformRepaymentDetails },
      { path: '/loanList', component: loanList },
      { path: '/loanListDetails', name: "loanListDetails", component: loanListDetails },
      { path: '/preliminaryReviewOfLoansList', name: "preliminaryReviewOfLoansList", component: preliminaryReviewOfLoansList },
      { path: '/preliminaryReviewOfLoansDetails', name: "preliminaryReviewOfLoansDetails", component: preliminaryReviewOfLoansDetails },
      { path: '/loanReviewList', name: "loanReviewList", component: loanReviewList },
      { path: '/loanReviewDetails', name: "loanReviewDetails", component: loanReviewDetails },
      { path: '/loanProcessingList', name: "loanProcessingList", component: loanProcessingList },
      { path: '/loanProcessingDetails', name: "loanProcessingDetails", component: loanProcessingDetails },
      { path: '/Detailed/:id', name: 'Detailed', component: Detailed },
      { path: '/orderpayment/:id', name: 'orderpayment', component: orderpayment },
      { path: '/productManagementList', name: "productManagementList", component: productManagementList },
      { path: '/productManagementDetails', name: "productManagementDetails", component: productManagementDetails },
      { path: '/addProduct', name: "addProduct", component: addProduct },
      { path: '/invoiceFindList', name: 'invoiceFindList', component: invoiceFindList },
      { path: '/invoiceDetails', name: 'invoiceDetails', component: invoiceDetails },
      { path: '/payment', name: 'payment', component: payment },
      { path: '/updateInvoice', name: 'updateInvoice', component: updateInvoice },
      { path: '/bulkPayment', name: 'bulkPayment', component: bulkPayment },
      { path: '/whiteList', name: 'whiteList', component: whiteList },
      { path: '/whiteListDetails', name: 'whiteListDetails', component: whiteListDetails },
      { path: '/platformPayList', name: 'platformPayList', component: platformPayList },
      { path: '/financialSummary', name: 'financialSummary', component: financialSummary },
      { path: '/personnelDetails', name: 'personnelDetails', component: personnelDetails },

      { path: '/fundAccountList', name: 'fundAccountList', component: fundAccountList },
      { path: '/summaryDetails', name: 'summaryDetails', component: summaryDetails },
      { path: '/summaryApprovalDetails', name: 'summaryApprovalDetails', component: summaryApprovalDetails },
      { path: '/salaryApproval', name: 'salaryApproval', component: salaryApproval },
      { path: '/salaryList', name: 'salaryList', component: salaryList },
      { path: '/spiritualLabor', name: 'spiritualLabor', component: spiritualLabor },
      { path: '/spiritualLaborApproval', name: 'spiritualLaborApproval', component: spiritualLaborApproval },
      { path: '/contractList', name: 'contractList', component: contractList },
      { path: '/templateList', name: 'templateList', component: templateList },
      { path: '/BatchSignTaskList', name: 'templateList', component: batchSignTaskList },
      { path: '/signContract', name: 'signContract', component: signContract },
      { path: '/subAccountList', name: 'subAccountList', component: subAccountList },
      { path: '/PayrollDeclarationList', name: 'PayrollDeclarationList', component: PayrollDeclarationList },
      { path: '/ptdetail', name: 'ptdetail', component: ptdetail },
      { path: '/zcdetails', name: 'zcdetails', component: zcdetails },
      { path: '/transfer', name: 'transfer', component: transfer },
      { path: '/pages', name: 'pages', component: pages },
      { path: '/tran', name: 'tran', component: tran },
      { path: '/qrCord', name: 'qrCord', component: qrCord },
      // 计费规则 
      { path: '/listPageItem', name: 'listPageItem', component:  () => import('@/views/billing/index')},
      // 方案管理 
      { path: '/listPaidPlan', name: 'listPaidPlan', component:  () => import('@/views/scheme/index')},
      // 薪资管理
      // { path: '/salaryList', name: 'salaryList', component:  () => import('@/views/salary/index')},
      //发薪记录
      { path: '/payrollLog', name: 'payrollLog', component:  () => import('@/views/salary/payrollLog'),meta:{keepAlive:true}},
      { path: '/satffIncomelLog', name: 'satffIncomelLog', component:  () => import('@/views/salary/satffIncomelLog')},
      { path: '/dataReport', name: 'dataReport', component:  () => import('@/views/salary/dataReport')},
      { path: '/authFailedList', name: 'authFailedList', component:  () => import('@/views/salary/authFailedList')},
      { path: '/invoiceList', name: 'invoiceList', component:  () => import('@/views/invoice/invoiceList')},
      { path: '/invoiceApproval', name: 'invoiceApproval', component:  () => import('@/views/invoice/invoiceApproval')},
      { path: '/invoiceWritebackList', name: 'invoiceWritebackList', component:  () => import('@/views/invoice/invoiceWritebackList')},
      { path: '/payrollLogDetails', name: 'payrollLogDetails', component:  () => import('@/views/salary/payrollLogDetails')},
      { path: '/payrollSummaryList', name: 'payrollSummaryList', component:  () => import('@/views/payrollSummary/payrollSummaryList')},
      { path: '/salaryCalculation', name: 'salaryCalculation' , component: () => import('@/views/payrollSummary/salaryCalculation')},
      { path: '/payrollSummaryApproval', name: 'payrollSummaryApproval' , component: () => import('@/views/payrollSummary/payrollSummaryApproval')},
      { path: '/easyPayList', name: 'easyPayList' , component: () => import('@/views/salary/easyPayList')},

      { path: '/salaryList', name: 'platformPayList', component: salaryList },
      // { path: '/index', name: 'index', component: index },
      // 计费规则
      { path: '/listPageItem', name: 'listPageItem', component:  () => import('@/views/billing/index')},
      // { path: '/payrollList', name: 'payrollList', component: payrollList },
    ]
  }
]

const router = new VueRouter({
  routes
})


router.beforeEach((to,from,next)=>{
	console.log('前置路由守卫',to,from);
  next();
})


export default router
